.Lista ul {
  display: flex;
  list-style: none;
  justify-content: space-between;
  list-style-type: none;
}

.lista li {
  display: flex;
  list-style-type: none;
}

.number {
  color: white;
}

.infoheader {
  padding-top: 10px;
  padding: 20px;
}

.menu {
  color: white;
  box-shadow: 0px 11px 14px -10px rgba(0, 0, 0, 0.75);
  z-index: 6;
  height: 80px;
}
.menuresponsive {
  text-align: left;
  color: white;
  font-weight: 700;
}
.cajalogo {
  color: white;
}

.logoeasy {
  top: 1px;
  height: 70px;
}

.top {
  margin-top: 130px;
}

.iconomenuresponsive {
  margin-top: 11px;
  margin-left: 20px;
  font-size: 20px;
  position: absolute;
  top: 20px;
  right: 22px;
  display: none;
}

.calculadora {
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 70px;
  align-content: center;
}

.modelocalculadora img {
  margin-left: -50px;
  width: 100%;
}

.botonesRegistro {
  margin: 0 auto;
}

.requisitos {
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: center;
}
.logo1 {
  position: absolute;
  top: -1px;
  width: 200px;
  float: left;
}

.pasos {
  height: 600px;
  display: flex;
}

.contenedorrequisitos {
  background: #ffe666;
  font-weight: 600;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  height: 600px;
}
.contenedorrequisitos1 {
  background: #ffe666;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
}

.cajarequisitos {
  padding-top: 30px;
  margin-top: 55px;
  margin-bottom: 50px;
}
.cajarequisitos1 {
  padding-top: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.requisitos1 {
  display: flex;
  flex-direction: row;

  justify-content: center;
  background-color: #ffe666;
}
.cajarequisitos h2 {
  text-align: center;
  color: #4382ce;
  font-size: 40px;
}
.requisitoscaja {
  width: 400px;
  height: 200px;
  margin: 12px;
  padding: 45px;
  text-align: center;
  background: white;
  border-radius: 24px;
}

.requisitotexto {
  font-size: 20px;
  line-height: 20px;
  margin-top: 9px;
  color: black;
}
.requisitotexto1 {
  font-size: 20px;
  line-height: 20px;
  margin-top: 9px;
  display: flex;
  align-items: center;
  font-weight: 700;
}
.tituloporque2 h2 {
  text-align: center;
  font-weight: 700;
  color: #4382ce;
  font-size: 35px;
}
.tituloporque3 h2 {
  color: #3973b8;
  background-color: #ffe666;
  height: 100px;
  font-weight: 900;
  font-size: 20px;
  justify-content: center;
}
.requisitoscaja:hover {
  box-shadow: -2px 1px 11px 0px;
  color: black;
}
.contenedorpasos {
  background: white;
  margin-top: auto;
}

.cajapasos {
  margin: 0 auto;
}

.cajapasos h1 {
  text-align: center;
  color: #4382ce;
  font-size: 40px;
  text-decoration: none;
  outline: 0;
  margin: 0 auto;
  list-style-type: none;
  padding-top: 40px;
}
.datos1 {
  font-weight: 900;
  text-align: center;
  font-size: 40px;
  text-decoration: none;
  outline: 0;
  margin: 0 auto;
  list-style-type: none;
}

.contenidopasos {
  color: white;
  padding-top: 2px;
  font-size: 22px;
  width: 280px;
  height: 100px;
  background: #ffe666;
  margin-top: 30px;
  margin-right: 40px;
  margin-left: 50px;
  margin-bottom: 60px;
  position: relative;
  border-radius: 10px;
}

.circlecontenidopasos1 {
  position: absolute;
  top: 10px;
  width: 100%;
  text-align: center;
}

.circlecontenidopasos1 p {
  color: #0074bc;
  font-weight: 600;
}

/* .contenidopasos h6 {
  position: absolute;
  background: #4382ce;
  width: 50px;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -30px;
  border-radius: 50%;
  top: 5px;
  font-size: 25px;
  margin: 0;
} */

.elegirnoscaja h1 {
  color: white;
  font-weight: 900;
  text-align: center;
  font-size: 40px;
}

.contenedor_porqueelegirnos {
  display: flex;
  flex-direction: column;
  background: #3973b8;
  height: 500px;
}
.root {
  text-align: left;
  color: #f9f9f9;
  font-weight: 700;
  background-color: #3973b8;
  margin-top: 0 !important;
}
.root2 {
  text-align: left;
  color: #3973b8;
  font-weight: bold;
  background-color: white;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 50px;
  align-items: center;
}

.banner-inner {
  width: 100%;
  margin-top: 150px;
  margin-bottom: 150px;
}

.tituloroot {
  background-color: #ffe666;
}
.titulo-porque-elegirnosroot {
  color: #f9f9f9;
  display: flex;
  background-color: #3973b8;
  align-content: center;
  font-size: 23px !important;
  margin-bottom: 15px !important;
}

.titulo-porque-elegirnos {
  margin: 20px 0;
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 2.5rem;
}
.titulo-porque-elegirnoscard {
  margin: 20px 0;
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 10;
  align-content: center;
}

.titulo-8pasospararoot {
  display: flex;
  align-items: center;
  font-size: 1.5rem !important;
  margin-bottom: 5px;
  font-weight: 800 !important;
}

.contenedor_InformacióndeContacto {
  display: flex;
  flex-direction: column;
  background: white;
}

section#contacto h1 {
  text-align: center;
  font-size: 30px;
}

.tituloporque h2 {
  color: #3973b8;
  background-color: #ffe666;
  height: 100px;
  font-weight: 900;
  font-size: 40px;
}

.titulo_InformacióndeContacto {
  display: block;
  color: #3973b8;
  font-weight: 900;
}

.titulo_InformacióndeContacto ul {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 3rem;
}

.titulo_InformacióndeContacto ul li {
  margin-top: 1rem;
}

.titulo_InformacióndeContacto ul li span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 200;
  font-size: 16px;
}

.titulo_InformacióndeContacto li span a {
  text-decoration: none;
  color: #3973b8;
}

.titulo_InformacióndeContacto li span a:hover {
  color: #ffe666;
}

.titulo_InformacióndeContacto2 {
  margin: 20px 0;
  text-align: right;
  background: red;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  background: white;
  height: 500px;
  margin-top: 50px;
}

.titulo_InformacióndeContacto3 {
  margin: 20px 0;
  text-align: right;
  font-weight: 900;
  display: flex;
  margin-left: 40px;
  background: white;
  height: 250px;
}
.opciones {
  width: 100%;
}

.cambioa {
  text-decoration: none;
  color: black;
}

a:hover {
  color: #ffe666;
  text-decoration: none;
}

.opcion-item {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.opcion-item h1 {
  font-size: 20px;
  color: white;
  text-align: left !important;
}

.opcion-item p {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  background-color: #ffe666;
  height: 30px;
  width: 600px;
  border-radius: 20px;
  justify-content: space-between;
  display: flex;
  padding-left: 30px;
  box-shadow: -2px 1px 11px 0px;
}

.porcentaje {
  font-weight: 800;
  margin-left: 404px;
  margin-top: 3px;
}

.lista {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 73px;
}

.quienessomos {
  background: #f9f9f9;
  height: 660px !important;
  margin-bottom: 75px;
}
.fon {
  margin-top: 100px;
}

#porqueelegirnos {
  margin-top: 3rem;
}

.banner {
  width: 100%;
  height: 150px;
}

.bannerp {
  margin-bottom: 150px;
}
.banner-item.active img {
  width: 100%;
}

.cajacontenedorcontactoabajo {
  float: left;
}
.cajacontenedorcontactoabajo h1 {
  color: #4382ce;

  font-weight: 800;
  font-size: 30px;
}

.espacioformcontacto {
  height: 10px;
}
.tituloinformacioncontacto {
  color: #3973b8;
  margin-right: 5px;
}

.contactoabajo {
  width: 70%;
  margin: 0 auto;
  height: 400px;
  background: white;
  padding-top: 60px;
}
.facebook {
  width: 50px;
  height: 30px;
  justify-content: space-around;
}
.instagram {
  width: 50px;
  height: 30px;

  margin-left: -47px;

  justify-content: space-around;
}

.instagram:hover {
  border-radius: 100px;
}
.prueba {
  font-size: 20px;
  margin: 0 auto;
  color: black;
  justify-content: space-around;
}
.iconoface {
  color: #ffe666;
  padding-top: 0px;
}

.iconoface:hover {
  color: white;
}
.facebook:hover {
  border-radius: 100px;
  cursor: pointer;
}
.direccion1 {
  width: 30px;
  height: 30px;
  background-color: #ffe666;
}
.iconoadress {
  color: #ffe666;
  padding-top: 10px;
  font-family: Font Awesome 5 Free;
}
.iconoadress:hover {
  color: white;
}
.direccion1:hover {
  border-radius: 100px;
  cursor: pointer;
}
.contenedorfooter {
  text-align: center;
  padding-top: 20px;
}
.footer {
  background: #3973b8;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.headerFirstLine {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0;
  color: black;
}

.headerSecondLine {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
}

.headerFirstLineLI {
  list-style: none;
  flex-wrap: wrap;
}

.headerSecondLine li a {
  font-size: 20px;
}

.headerFirstLineLI span {
  font-size: 17px;
  align-items: center;
  display: flex;
  justify-content: center;
}

svg.MuiSvgIcon-root.headerIcon {
  margin-right: 5px !important;
}

.logofooter {
  position: absolute;
  top: -1px;
  float: left;
}
.textoeasyfooter {
  color: white;
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 900;
}
.tittlefollowfooter {
  float: left;
  color: white;
  font-weight: 800;
}
.cajasocialmediafooter {
  display: flex;
  flex-direction: row;
  margin: 10px;
}
.infocontactofooter {
  color: white;

  font-weight: 800;
  font-size: 25px;

  float: left;
}
.direccionfooter {
  float: left;
  list-style-type: none !important;
}
.adressfooter {
  color: white;
  margin-left: 20px;
}
.footerbottom {
  background: #ffe666;
  width: 100%;
  height: 40px;
  text-align: center;
}
.footerbottom h6 {
  padding-top: 10px;
  text-align: center;
  margin: 0;
  font-size: 14px;
  font-weight: 900;
}
.modeloquienessomos {
  float: left;
  z-index: 1;
}
.imgmodeloquienessomos {
  height: 650px;
}
.quienesomostexto {
  float: right;
  z-index: 1;
}
.quienesomostexto h6 {
  color: #3973b8;
  font-size: 45px;

  font-weight: 900;
  text-align: center;
  margin-top: 6px;
}
.cajaquienesson {
  text-align: center;
  font-size: 15px;
  padding: 42px;

  margin-bottom: 150px;
  flex-wrap: wrap;
}
.cajaquienesson p {
  font-size: 18px;
}
.cajaquienesson h6 {
  color: #3973b8;
  font-size: 35px;

  font-weight: 900;
}

.p {
  margin-top: 7px;
  font-weight: 600;
  margin-left: 43px;
}

.icono {
  color: #3973b8;
  margin-right: 5px;
}

.iconPhone {
  color: #3973b8;
}

.iconoamarillo {
  color: #ffe666;
}

.rotate {
  transform: rotateY(180deg);
  margin-left: -10px;
}

.contenedorrequisitos1 {
  background: #4382ce;
}
li {
  list-style-type: none;
}

.wpIcon {
  display: block;
  width: 60px;
  height: 60px;
  color: #fff;
  position: fixed;
  right: 20px;
  bottom: 20px;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  z-index: 999;
}

.wpIcon img {
  max-width: 100%;
}

/* Responsive */

.menuresponsive li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

#buttonNav {
  margin: auto;
  border: 1px solid;
}

.buttons {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.root {
  display: flex;
}

.buttonMU {
  box-shadow: none;
  margin-left: 10px;
  margin-top: 10px;
  text-transform: none;
  text-align: center;
  font-size: 16px;
  padding: 6px 12px;
  border: 1px solid;
  line-height: 1.5;
  width: 200;
  height: 45;
  border-radius: 5;
  background-color: #0074bc;
  border-color: #0063cc;
}

.appBarContainer {
  background: rgba(254, 255, 250, 0.9) !important;
  /* transition: theme.transitions.create([margin, width], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }), */
}

/* appBarShift: {
  transition: theme.transitions.create([margin, width], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
}, */

.title {
  flex-grow: 1;
  padding-top: 0 !important;
  width: 100%;
}

.hide {
  display: none;
}

.drawer {
  flex-shrink: 0;
}

/* contentShift: {
  transition: theme.transitions.create(margin, {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginRight: 0,
}, */

.drawerHeaders {
  width: 250px;
}

.drawerpaper {
  background-color: #0074bc;
}

.numero {
  font-size: 50px;
  position: absolute;
  top: -142px;
  left: -14px;
  color: #0074bc;
}
