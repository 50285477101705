.rootLiquidar {
  margin: auto !important;
  width: 80%;
}

.contenedor {
  display: flex;
  flex-direction: column;
}
.Header {
  display: flex;
  justify-content: space-between;
  margin: 5px 30px 5px 30px;
  margin-right: 5px;
}

.infoHeader {
  margin: 5px 30px 5px 30px;
  margin-right: 5px;
}

.p {
  margin: 0;
}

.pPrestamo {
  margin: 0;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 10px;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.subFooter {
  display: flex;
  flex-direction: column;
}

.boton {
  background: #ffde2e;
  font-weight: 600;
  border-radius: 13px;
  margin: 5px;
  font-size: 9px;
  width: 100%;
}

.alerta {
  margin-bottom: 20px;
  margin: 0 auto;
}

.contenedorfooter {
  display: flex;
  justify-content: space-between;
}

.botones {
  display: flex;
  justify-content: center;
}

.logo {
  width: 250px;
}

.pPrestamoDescripcion {
  margin: 0;
  font-size: 10px;
}

.espacio {
  margin: 20px 0px;
}

.contenedorSubtitulo {
  display: flex;
}
