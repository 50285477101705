.body {
  font-family: Arial, sans-serif;
  font-size: 22px !important;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: #333;
}

.container {
  width: 80%;
  margin: auto;
  overflow: hidden;
}

.header {
  background: #3973b8;
  color: #fff;
  padding-top: 30px;
  min-height: 70px;
  border-bottom: #2d5a91 3px solid;
}

.header h1 {
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  font-size: 26px !important;
}

.section {
  background: #fff;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.h2 {
  color: #3973b8;
  font-size: 24px !important;
}

.highlight {
  color: #d9534f;
}

.section-title {
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.info {
  margin: 20px 0;
  padding: 10px;
  background: #e6f7f6;
  border-left: 5px solid #3973b8;
}

.ul {
  list-style: none;
  padding: 0;
}

.ul li {
  background: #f4f4f4;
  margin: 5px 0;
  padding: 10px;
  border-left: 5px solid #3973b8;
  margin-top: 10px;
}

.benefits-list li {
  background: #eef5fb;
  border-left: 5px solid #5a9bd4;
  margin: 10px 0;
  padding: 10px;
}

.benefits-list li strong {
  color: #3973b8;
}

.h3 {
  font-weight: bold;
  margin-top: 20px;
  font-size: 20px !important;
}

.consejos-list li:nth-child(odd) {
  background: #f9f9f9;
}

.consejos-list li:nth-child(even) {
  background: #ffffff;
}
