.countdown-container {
    padding: 18px;
    margin-bottom: 10px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 10px;
}
 
.countdown-container .number {
    font-size: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 50px;
    margin-left: 50px;
    border-radius: 10px;
    color: #FFFFFF;
    background-color: #ffde2e;

}
 
.countdown-container .concept {
    font-size: 50px;
    text-align: center;
    color: #000000;
}

.contenedorContador{
    display: flex;
    width: 100%;
    justify-content: center;
}

.tmora {
    text-align: center;
    color: red;
}
