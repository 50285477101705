.title {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 10px;
}

.negrilla {
  font-weight: bold;
}

.parrafos {
  padding: 60px;
  text-align: justify;
}

.paper {
  margin-top: 20px;
  margin-left: 70px;
  margin-right: 70px;
}
.root {
  width: 100%;
}

.heading {
  font-size: 15rem;
}

.secondaryheading {
  font-size: 15rem;
}

.alerta {
  margin-bottom: 15px;
}

.container {
  text-align: justify;
  margin: '0 61px !important';
}
